import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import moment from 'moment';
import 'rc-checkbox/assets/index.css';
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { api } from '../../../../Config';
import DirectPagination from '../../../components/DirectPagination';
import UserImg from './Images/perfil-icone.png';
import { Notify } from '../../../../components/Notify';
import * as routes from '../../../../routes';
import * as mask from '../../../../utils/Mascaras';
import { NavBar } from '../../../components/NavBar';
import Pagination from '../../../components/Pagination';
import { SideBar } from '../../../components/SideBar';
import { UserDataContext } from '../../../../hooks/useUserData';

import './style.css';

class Players extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  getInitialState() {
    const initialState = {
      isLoadingPlayersForExport: false,
      isLoading: false,
      searchObj: {
        name: '',
        actionId: '',
        email: '',
        regional: '',
        cpf: '',
      },
      players: [],
      actions: [],
      regionalList: [],
      loadingPlayers: false,
      loadingRegionalList: false,
      searching: false,
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      isLoadingPlayers: false,
      startANext: '',
    };

    return initialState;
  }

  componentDidMount() {
    this.loadActionList();
    this.getClientRegionalList();
  }

  handleNextPage() {
    let { currentPage } = this.state;
    currentPage += 1;

    const startANext = this.state.startANext;
    this.filterList(currentPage, startANext);

    this.setState({ currentPage });
  }

  handlePreviousPage() {
    let { currentPage } = this.state;
    currentPage -= 1;

    if (currentPage < 0) return;

    const startANext = this.state.startANext;

    this.filterList(currentPage, startANext);

    this.setState({ currentPage });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  async exportPlayers() {
    const { groupPermissions, userData } = this.context;
    this.setState({ isLoadingPlayersForExport: true }, () => {
      const parameters = {
        searchFunctionality: 'getPlayersForExportation',
        userType: 'client',
        clientId: userData.clientId,
        userEmail: userData.email,
      };

      api
        .post(`/exportation`, parameters)
        .then((res) => {
          const result = res.data;
          if (result.success) {
            this.setState({ isLoadingPlayersForExport: false });
            Notify(result.message, 'success');
          } else {
            Notify(result.message, 'warn');
            this.setState({ isLoadingPlayersForExport: false });
          }
        })
        .catch((error) => {
          this.setState({ isLoadingPlayersForExport: false });
          console.log('getPlayers error:', error);
        });
    });

    return;
  }

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
    });
  }

  getClientRegionalList() {
    const { clientData } = this.context;
    const clientId = clientData.uid;

    this.setState({ loadingRegionalList: true }, () => {
      const parameters = {
        searchFunctionality: 'getClient',
        userType: 'administrator',
        clientId,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const clientData = res.data.data;
          if (clientData) {
            this.setState({
              loadingRegionalList: false,
              regionalList: clientData.regional,
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingRegionalList: false });
          console.log('getClientByUid error:', error);
        });
    });
  }

  loadActionList() {
    const parameters = {
      searchFunctionality: 'allActions',
      userType: 'client',
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        this.setState({ ...result.data });
      })
      .catch((error) => {
        console.log('loadActionList error:', error);
      });
  }

  filterList(selectedPage = 0, startANext = '') {
    const { userData, clientData } = this.context;
    const idUser = userData.uid;
    const userMaster = userData.master;

    this.setState({ isLoadingPlayers: true, isLoadingPlayersForExport: true });
    const { searchObj } = this.state;

    this.setState({ searching: true }, () => {
      let functionality = 'getPlayersWithPagination';
      let userId = null;
      if (
        this.context.clientData.enableUserAndPlayerLinking &&
        this.context.clientData.enableUserAndPlayerLinking === 'true' &&
        !userMaster
      ) {
        functionality = 'getPlayersWithLinkingUserAndPlayers';
        userId = idUser;
      }

      const parameters = {
        searchFunctionality: functionality,
        userType: 'client',
        page: selectedPage,
        startANext,
        searchOnPlayers: true,
        userId,
      };

      if (
        searchObj.name ||
        searchObj.actionId ||
        searchObj.email ||
        searchObj.regional ||
        searchObj.cpf
      ) {
        parameters.filter = searchObj;
      }

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          if (
            this.context.clientData.enableUserAndPlayerLinking &&
            this.context.clientData.enableUserAndPlayerLinking === 'true' &&
            !userMaster
          ) {
            if (result.success) {
              this.setState({
                ...result.data,
                searching: false,
                isLoadingPlayers: false,
                isLoadingPlayersForExport: false,
              });
              this.tableData();
            } else {
              Notify(result.message, 'warn');

              this.setState(this.getInitialState());
              // this.getInitialState();
            }
          } else {
            if (result.success) {
              this.setState({
                ...result.data,
                searching: false,
                isLoadingPlayers: false,
                startANext: result.data.startANext
                  ? result.data.startANext
                  : '',
                isLoadingPlayersForExport: false,
              });
            } else {
              this.setState(this.getInitialState());
            }
          }
        })
        .catch((error) => {
          // this.setState({ searching: false, isLoadingPlayers: false });
          this.setState(this.getInitialState());
          console.log('getPlayers error:', error);
        });
    });
  }

  handleSearchInputChange = async (e) => {
    let { name, value } = e.target;
    if (name === 'regional') {
      value = value
        .trim()
        .replace(/ /gi, '_')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
    }
    await this.setState((prevState) => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  };

  handleSearchInputChangeCpf = async (name, value) => {
    await this.setState((prevState) => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  };

  renderSearchForm() {
    const { groupPermissions, userData } = this.context;

    let isPermissionExportPlayers = null;

    // quando for login do grupo master
    if (userData && userData.master) {
      isPermissionExportPlayers =
        (groupPermissions &&
          groupPermissions.permissions &&
          groupPermissions.permissions.players &&
          groupPermissions.permissions.players.export) ||
        (userData && userData.master && userData.master);
    }

    // quando for login de qualquer outro grupo
    if (userData && !userData.master) {
      isPermissionExportPlayers =
        groupPermissions &&
        groupPermissions[userData.group.value] &&
        groupPermissions[userData.group.value].permissions &&
        groupPermissions[userData.group.value].permissions.players &&
        groupPermissions[userData.group.value].permissions.players.export;
    }

    return (
      <div>
        <div>
          <div className="oq-filter-bg">
            <div className="container-fluid">
              <span className="form-group oq-filter-title">
                <i className="fa fa-sliders" aria-hidden="true" />
                &nbsp; {this.translate('advanced-filters')}
              </span>
              <div className="row">
                <div className="col-md-3 form-group">
                  <input
                    className="form-control input-oq"
                    placeholder={this.translate('Nome completo')}
                    name="name"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.filterList();
                      }
                    }}
                    onChange={(e) => this.handleSearchInputChange(e)}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <input
                    className="form-control input-oq"
                    placeholder="CPF"
                    name="cpf"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.filterList();
                      }
                    }}
                    type="tel"
                    value={this.state.searchObj.cpf}
                    onChange={(event) => {
                      const input = event.target;
                      if (input.value.length <= 14) {
                        let maskCPF = mask.maskCPF(input.value);
                        this.handleSearchInputChangeCpf(input.name, maskCPF);
                      } else if (
                        input.value.length > 14 &&
                        input.value.length <= 18
                      ) {
                        let maskCNPJ = mask.maskCNPJ(input.value);
                        this.handleSearchInputChangeCpf(input.name, maskCNPJ);
                      } else {
                        let maskLimited = mask.maskLimited(input.value);
                        this.handleSearchInputChangeCpf(
                          input.name,
                          maskLimited
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <select
                    className="form-control input-oq"
                    placeholder="Ações"
                    name="actionId"
                    disabled={this.state.loadingPlayers}
                    onChange={(e) => this.handleSearchInputChange(e)}
                  >
                    <option value="">
                      {this.state.loadingPlayers
                        ? this.translate('loading')
                        : this.translate('Selecione uma ação...')}
                    </option>
                    {_.size(this.state.actions) > 0 &&
                      _.map(this.state.actions, (action, idx) => (
                        <option key={idx} value={action.id}>
                          {action.actionTitle}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="col-md-3 form-group">
                  <button
                    className="btn btn-block btn-oq pull-right"
                    style={{ width: '75%' }}
                    disabled={
                      this.state.searching ||
                      this.state.isLoadingPlayersForExport
                    }
                    onClick={() => this.filterList()}
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                    &nbsp;
                    {this.state.searching
                      ? this.translate('Pesquisando...')
                      : this.translate('Pesquisar')}
                  </button>

                  <button
                    className="btn btn-oq pull-right"
                    style={{ marginTop: 10 }}
                    onClick={() =>
                      isPermissionExportPlayers
                        ? this.exportPlayers()
                        : Notify('Você não tem permissão!', 'warn')
                    }
                    disabled={
                      this.state.isLoadingPlayersForExport ||
                      this.state.isLoadingPlayers
                    }
                  >
                    <i className="fa fa-file-excel-o" aria-hidden="true" />
                    {this.translate('Exportar')}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 form-group">
                  <input
                    className="form-control input-oq"
                    placeholder="E-mail completo"
                    name="email"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.filterList();
                      }
                    }}
                    onChange={(e) => this.handleSearchInputChange(e)}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <select
                    className="form-control input-oq"
                    placeholder="Regional"
                    name="regional"
                    disabled={this.state.loadingPlayers}
                    onChange={(e) => this.handleSearchInputChange(e)}
                  >
                    <option value="">
                      {this.state.loadingRegionalList
                        ? this.translate('loading')
                        : this.translate('Selecione uma unidade...')}
                    </option>
                    {this.state.regionalList &&
                      _.size(this.state.regionalList) > 0 &&
                      _.map(this.state.regionalList, (division, idx) => (
                        <option key={idx} value={division}>
                          {division}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderNewPlayer()}
      </div>
    );
  }

  renderNewPlayer() {
    return (
      <div className="col-md-12 oq-padding-top">
        <div className="form-group col-md-4">
          <button
            disabled={
              this.state.searching || this.state.isLoadingPlayersForExport
            }
            onClick={() => this.goToNewPlayerPage()}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp;{' '}
            {this.state.searching || this.state.isLoadingPlayersForExport
              ? this.translate('loading')
              : 'Novo Usuário'}
          </button>
        </div>
      </div>
    );
  }

  goToNewPlayerPage() {
    const { history } = this.props;
    history.push(routes.CREATE_PLAYER);
  }

  escolheCor(total) {
    if (total >= 0 && total <= 999) {
      return 'rgb(255, 220, 83)';
    } else if (total > 999 && total <= 4999) {
      return 'rgb(122, 82, 255)';
    } else if (total > 4999 && total <= 9999) {
      return 'rgb(255, 152, 87)';
    } else if (total > 9999 && total <= 19999) {
      return 'rgb(24, 255, 209)';
    } else if (total > 19999 && total <= 49999) {
      return 'rgb(85, 85, 85)';
    } else if (total > 49999) {
      return 'rgb(0, 35, 99)';
    }
  }

  tableData() {
    const playerss = _.values(this.state.players);
    const { searchObj } = this.state;
    var slice;

    if (
      searchObj.name ||
      searchObj.actionId ||
      searchObj.email ||
      searchObj.regional ||
      searchObj.cpf
    ) {
      slice = playerss.slice(0, 0 + 10);

      this.setState({
        pageCount: Math.ceil(playerss.length / 10),
        orgtableData: playerss,
        tableData: slice,
        currentPage: 0,
        offset: 0,
      });
    } else {
      slice = playerss.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );

      this.setState({
        pageCount: Math.ceil(playerss.length / this.state.perPage),
        orgtableData: playerss,
        tableData: slice,
      });
    }
  }

  renderActionsTable() {
    const { clientData } = this.context;
    return (
      <div className="col-md-12">
        {!this.state.isLoadingPlayers &&
          this.state.players &&
          this.state.players.length > 0 &&
          _.map(this.state.players, (player, index) => {
            return (
              <div
                className="list-item-oq"
                style={{ borderTopColor: this.escolheCor(player.totalPoints) }}
                key={index}
              >
                <div className="box-group-info">
                  <div className="content-list-info">
                    <div className="profile-image-list">
                      <img
                        style={{
                          width: '40px',
                          height: '40px',
                          marginRight: '20px',
                        }}
                        src={UserImg}
                        alt=""
                      />
                    </div>
                    <div className="text-info-oq">
                      <p>{player.name}</p>
                      <span>{player.cpf}</span>
                      <span>{player.email}</span>
                    </div>
                  </div>
                </div>
                <div className="box-group-info">
                  <p>Cidade/Estado</p>
                  <span>{player.city ? player.city : '-'}</span>
                  <span>{moment(player.createdAt).format('DD/MM/YYYY')}</span>
                </div>
                <div className="box-group-info">
                  <p>
                    {clientData &&
                      clientData.scopeFieldName &&
                      clientData.scopeFieldName}
                  </p>
                  {clientData.clientUrl === 'bild' ? (
                    <span>
                      {player.regional ? player.regional[0].label : '-'}
                    </span>
                  ) : (
                    <span>{player.regional ? player.regional : '-'}</span>
                  )}
                </div>
                <div className="box-group-info">
                  <p>Leads</p>
                  <span>{player.leads ? player.leads : '0'}</span>
                </div>
                <div className="box-group-info">
                  <p>Pts dos leads</p>
                  <span>{player.pointsLeads ? player.pointsLeads : '0'}</span>
                </div>
                <div className="box-group-info">
                  <p>Pts totais</p>
                  <span>{player.totalPoints ? player.totalPoints : '0'}</span>
                </div>
                <div
                  className="box-group-info"
                  style={{ flexDirection: 'row', justifyContent: 'center' }}
                >
                  <Link to={`${routes.EDIT_PLAYER}/${player.uid}/`}>
                    Editar
                  </Link>
                  <Link to={`${routes.DETAILS_PLAYER}/${player.uid}/`}>
                    Visualizar
                  </Link>
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  foundPlayers() {
    return <p> Pesquise para ver os resultados</p>;
  }

  renderPagination() {
    this.state.players.length === 0 && this.foundPlayers();
    return (
      <>
        {!this.state.isLoadingPlayers && (
          <div className="pagination-group">
            <Pagination
              items={this.state.players}
              handleNextPage={() => this.handleNextPage()}
              handlePreviousPage={() => this.handlePreviousPage()}
              currentPage={this.state.currentPage}
              isNext={this.state.startANext !== '' ? true : false}
            />
          </div>
        )}
      </>
    );
  }

  renderReactPaginate() {
    this.state.players.length === 0 && this.foundPlayers();
    return (
      <>
        {this.state.tableData.length != 0 && (
          <div className="pagination-group">
            <DirectPagination
              pageCount={this.state.pageCount}
              currentPage={this.state.currentPage}
              onPageChange={this.handlePageClick}
            />
            <ReactPaginate
              previousLabel={'Anterior'}
              nextLabel={'Próxima'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              initialPage={this.state.currentPage}
              forcePage={this.state.currentPage}
            />
          </div>
        )}
      </>
    );
  }

  render() {
    const { userData } = this.context;
    const userMaster = userData.master;
    let isPagination = true;
    if (
      this.context.clientData.enableUserAndPlayerLinking &&
      this.context.clientData.enableUserAndPlayerLinking === 'true' &&
      !userMaster
    ) {
      isPagination = false;
    }

    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            {this.renderSearchForm()}
            {this.renderActionsTable()}
            <div className="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {isPagination
                ? this.renderPagination()
                : this.renderReactPaginate()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PlayersLink = (props) => (
  <Link className="menu-link" to={routes.PLAYERS}>
    <FontAwesomeIcon icon={faUsers} />
    <span className="sidebarText">{props.translate('players')}</span>
  </Link>
);

export default Players;

export { PlayersLink };
