import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import Select from 'react-select';
import ReactTable from 'react-table';
import ToggleButton from 'react-toggle-button';
import _ from 'underscore';
import axios from 'axios';
import moment from 'moment';
import { api } from '../../../../Config';
import keyBy from 'lodash.keyby';
import { Link } from 'react-router-dom';
import avatarImg from '../../../../Assets/Images/avatar.png';
import { Notify } from '../../../../components/Notify';
import * as routes from '../../../../routes';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { UserDataContext } from '../../../../hooks/useUserData';
import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';
import Rating from '@mui/material/Rating';
import { withStyles } from '@material-ui/core/styles';

const StyledRating = withStyles({
  iconFilled: {
    color: '#ffc107',
  },
})(Rating);

class DetailsPlayer extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      loadingPlayer: true,
      loadingPlayers: true,
      value: false,
      player: {},
      stateList: {},
      cityList: {},
      actions: [],
      actionsCount: 0,
      playerId: null,
      loadingActions: false,
      updatingPlayer: false,
      regional: [],
      pointsLeads: 0,
      rating: 5,
    };

    this.translate = props.t;
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.getStateList();
    this.getCityList();
    this.setState({ playerId: params.playerId });
    this.getPlayer(params.playerId);
    this.getParticipatedPlayerActions(params.playerId);
    this.getPlayersIndicated(params.playerId);
    this.getPlayerRating(params.playerId);

    if (
      this.context.clientData.isTeamCompetition &&
      this.context.clientData.isTeamCompetition === 'true' &&
      this.state.player &&
      !this.state.player.influencer &&
      this.state.player.influencer !== true
    ) {
      await this.getInfluencer();
    }
    if (
      this.context.clientData.isTeamCompetition &&
      this.context.clientData.isTeamCompetition === 'true'
    ) {
      this.getTeamMembers(this.context.clientData.uid, params.playerId);
    }

    this.setState({ regional: this.context.clientData.regional });
  }

  async getTeamMembers(clientId, playerId) {
    this.setState({ loadingTeamMembers: true }, () => {
      const parameters = {
        searchFunctionality: 'getTeamMembers',
        userType: 'client',
        clientId,
        playerId,
      };

      api
        .post(`/search`, parameters)
        .then(async (res) => {
          const result = res.data;

          if (result.success) {
            this.setState({
              teamLeader: result.data.teamLeader,
              teamMembers: result.data.teamMembers,
              loadingTeamMembers: false,
            });
          }
        })
        .catch((error) => {
          console.log('getTeamMembers error:', error);
          this.setState({ loadingTeamMembers: false });
        });
    });
  }

  getCityList(estado = 'MG') {
    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`
      )
      .then((city) => {
        this.setState({ cityList: city.data });
      })
      .catch((error) => {
        console.log('getCityList error:', error);
      });
  }

  async getInfluencer() {
    const parameters = {
      searchFunctionality: 'getInfluencers',
      userType: 'client',
    };

    const res = await api.post(`/search`, parameters);

    const result = res.data;

    this.setState({
      formData: {
        ...this.state.formData,
        listInfluencer: result.data.players,
      },
    });
  }

  getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  async getPlayer(playerId) {
    await this.getPlayerById(playerId);
  }

  async getPlayerById(playerId) {
    const { clientData } = this.context;
    this.setState({ loadingPlayer: true });

    const parameters = {
      searchFunctionality: 'getPlayer',
      userType: 'client',
      playerId,
      clientId: clientData && clientData.uid,
    };

    const res = await api.post(`/search`, parameters);

    const result = res.data;

    if (!result.success) {
      Notify(result.message, result.success ? 'success' : 'error');
      return;
    }
    const { player, pointsLeads } = result.data;

    this.setState({
      ...this.state,
      listPlayersHost: result.data.listPlayersHost,
      formData: {
        ...this.state.formData,
        player,
      },
      player,
      pointsLeads,
      loadingPlayer: false,
    });

    this.getCityList(player.state);
    if (!this.state.loadingPlayer && player) {
      Notify(result.message, result.success ? 'success' : 'error');
    }
  }

  getParticipatedPlayerActions(playerId) {
    const { uid } = this.context.clientData;
    this.setState({ loadingActions: true }, () => {
      let parameters = {
        searchFunctionality: 'getParticipatedPlayerActions',
        userType: 'client',
        playerId,
      };

      api
        .post(`/search`, parameters)
        .then(async (res) => {
          const result = res.data;
          const actions = keyBy(result.data.participatedActions, 'id');
          this.setState({ actions, loadingActions: false });

          _.each(actions, async (values) => {
            const actionId = values.id;
            parameters = {
              searchFunctionality: 'getActionRanking',
              userType: 'client',
              clientId: uid,
              actionId,
            };

            res = await api.post(`/search`, parameters);

            const rankingPlayer = res.data.data.filter((player) => {
              return player.uid === playerId;
            });

            actions[actionId].points = rankingPlayer[0]
              ? rankingPlayer[0].points
              : '--';
            actions[actionId].position = rankingPlayer[0]
              ? rankingPlayer[0].ranking
              : '--';

            this.setState({ actions });
          });
        })
        .catch((error) => {
          console.log('getParticipatedPlayerActions error:', error);
          this.setState({ loadingActions: false });
        });
    });
  }

  getPlayersIndicated(playerId) {
    this.setState({ loadingPlayers: true }, () => {
      const parameters = {
        searchFunctionality: 'getPlayersIndicatedMGM',
        userType: 'client',
        playerId,
      };

      api
        .post(`/search`, parameters)
        .then(async (res) => {
          const result = res.data;

          this.setState({
            playersIndicated: res.data.data,
            loadingPlayers: false,
          });
        })
        .catch((error) => {
          console.log('getParticipatedPlayerIndicated error:', error);
          this.setState({ loadingPlayers: false });
        });
    });
  }

  getPlayerRating = async (playerId) => {
    try {
      const { data } = await api.get(`/ratings/${playerId}`);

      if (data.body) {
        this.setState({ rating: data.body?.averageRating });
      }
    } catch (error) {
      console.log('getRating error: ', error);
    }
  };

  getState(item) {
    if (this.state.player) {
      return _.get(this.state, item, '');
    }
  }

  flatten(arr) {
    return arr
      ? arr.reduce(
          (result, item) => [
            ...result,
            { id: item.id, text: item.text },
            ...this.flatten(item.children),
          ],
          []
        )
      : [];
  }

  renderProfileFields() {
    const { player, listPlayersHost } = this.state;
    const playerHostInfo = _.find(
      listPlayersHost,
      (playerHost) => playerHost.uid === player.hostId
    );
    return (
      <div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Nome do usuário')}
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              id="name"
              name="name"
              value={player.name}
              placeholder={this.translate('Nome do usuário')}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="cpf">
              {this.translate('CPF do usuário')}
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              id="cpf"
              name="cpf"
              value={player.cpf}
              placeholder={this.translate('CPF do usuário')}
            />
          </div>
        </div>
        {player.birthDate && (
          <div className="col-md-12">
            <div className="form-group">
              <label className="profile-label" htmlFor="cpf">
                {this.translate('Data de nascimento')}
              </label>
              <input
                disabled
                readOnly
                type="date"
                className="form-control profile-placeholder"
                id="birthDate"
                name="birthDate"
                value={player.birthDate}
                placeholder={this.translate('Data de nascimento')}
              />
            </div>
          </div>
        )}
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('state')}
            </label>
            <select
              disabled
              className="form-control profile-placeholder"
              id="state"
              name="state"
              aria-describedby="stateHelp"
              value={player.state}
            >
              <option value="">Selecione um estado...</option>
              {this.state.stateList &&
                _.map(this.state.stateList, (state) => {
                  return <option value={state.sigla}>{state.nome}</option>;
                })}
            </select>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="city">
              {this.translate('city')}
            </label>
            <select
              disabled
              className="form-control profile-placeholder"
              id="city"
              name="city"
              aria-describedby="cityHelp"
              value={player.city}
            >
              <option value="">Selecione a cidade...</option>
              {this.state.cityList &&
                _.map(this.state.cityList, (city) => {
                  return <option value={city.nome}>{city.nome}</option>;
                })}
            </select>
          </div>
        </div>
        {this.context.clientData.isTeamCompetition &&
          this.context.clientData.isTeamCompetition === 'true' &&
          this.state.player &&
          !this.state.player.influencer &&
          this.state.player.influencer !== true && (
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="influencer">
                  Influencer
                </label>
                <select
                  disabled
                  className="form-control profile-placeholder"
                  id="influencerId"
                  name="influencerId"
                  aria-describedby="influencerHelp"
                  value={player.influencerId}
                >
                  <option value="">Selecione o influencer...</option>
                  {this.state.formData &&
                    this.state.formData.listInfluencer &&
                    _.map(this.state.formData.listInfluencer, (influencer) => {
                      return (
                        <option value={influencer.uid}>
                          {influencer.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          )}
        {this.context.clientData.segmentationChoice === 'true' && (
          <div className="col-md-12">
            <div className="form-group">
              <label className="profile-label" htmlFor="exampleInputEmail1">
                {this.context.clientData &&
                this.context.clientData.scopeFieldName
                  ? this.context.clientData.scopeFieldName
                  : this.translate('Região')}
              </label>
              {this.context.clientData.clientUrl === 'bild' && (
                <Select
                  isOptionDisabled={(option) => option.value}
                  isDisabled
                  isMulti
                  required
                  name="regional"
                  options={_.map(
                    this.context.clientData.regional,
                    (regional) => {
                      return {
                        value: normalizeLowerCase(regional),
                        label: regional,
                      };
                    }
                  )}
                  value={player.regional}
                  className="basic-multi-select"
                  defaultValue={player.regional}
                  classNamePrefix="select"
                />
              )}
              {this.context.clientData.clientUrl !== 'bild' && (
                <select
                  disabled
                  className="form-control profile-placeholder"
                  id="regional"
                  name="regional"
                  value={player.regional}
                >
                  <option value="">Escolha ...</option>
                  {this.context.clientData.regional &&
                    this.context.clientData.regional.map((regional) => (
                      <option
                        value={
                          regional ? normalizeLowerCase(regional) : regional
                        }
                        key={regional}
                      >
                        {regional}
                      </option>
                    ))}
                </select>
              )}
            </div>
          </div>
        )}
        {this.context.clientData.secondarySegmentationChoice === 'true' && (
          <div className="col-md-12">
            <div className="form-group">
              <label className="profile-label" htmlFor="exampleInputEmail1">
                Segunda Segmentação -{' '}
                {this.context.clientData.nameSecondarySegmentation}
              </label>
              <select
                disabled
                className="form-control profile-placeholder"
                id="secondarySegmentation"
                name="secondarySegmentation"
                value={player.secondarySegmentation}
              >
                <option value="">Escolha ...</option>
                {this.context.clientData.secondarySegmentation &&
                  this.flatten(
                    this.context.clientData.secondarySegmentation
                  ).map((segmentation) => (
                    <option value={segmentation.id} key={segmentation.id}>
                      {segmentation.text}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        )}

        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Celular')}
            </label>
            <input
              disabled
              readOnly
              className="form-control profile-placeholder"
              maxLength={15}
              minLength={15}
              id="phoneNumber"
              name="phoneNumber"
              aria-describedby="phoneNumberHelp"
              value={player.phoneNumber}
              placeholder="Telefone"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              E-mail
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              id="email"
              name="email"
              value={player.email}
              placeholder="E-mail"
            />
          </div>
        </div>
        {this.context.clientData &&
          this.context.clientData.clientUrl === 'indiqueeganhe' && (
            <div className="col-md-12">
              <div className="form-group">
                <label className="profile-label" htmlFor="correctors">
                  Corretor
                </label>
                <input
                  disabled
                  readOnly
                  type="text"
                  className="form-control profile-placeholder"
                  id="corrector"
                  name="corrector"
                  value={
                    player.corrector && player.corrector.label
                      ? player.corrector.label
                      : 'Nenhum'
                  }
                  placeholder="Corretor"
                />
              </div>
            </div>
          )}
        {this.state && this.state.player && this.state.player.hostId && (
          <>
            <div className="col-md-6">
              <div className="form-group">
                <label className="profile-label" htmlFor="exampleInputEmail1">
                  Anfitrião
                </label>
                <input
                  disabled
                  readOnly
                  type="text"
                  className="form-control profile-placeholder"
                  id="playerNameHost"
                  name="playerNameHost"
                  value={playerHostInfo && playerHostInfo.name}
                  placeholder="Anfitrião"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="profile-label" htmlFor="exampleInputEmail1">
                  E-mail do anfitrião
                </label>
                <input
                  disabled
                  readOnly
                  type="text"
                  className="form-control profile-placeholder"
                  id="playerEmailHost"
                  name="playerEmailHost"
                  value={playerHostInfo && playerHostInfo.email}
                  placeholder="E-mail do anfitrião"
                />
              </div>
            </div>
          </>
        )}
        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Data de adesão')}
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              id="createdAt"
              name="createdAt"
              value={moment(player.createdAt).format('DD/MM/YYYY')}
              placeholder={this.translate('Data de adesão')}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              {this.translate('Tempo de adesão')}
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              id="createdAtAdesao"
              name="createdAtAdesao"
              value={`${moment().diff(
                moment(player.createdAt),
                'days'
              )} dia(s)`}
              placeholder={this.translate('Tempo de adesão')}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="profile-label" htmlFor="exampleInputEmail1">
              Leads
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              id="leadsCount"
              name="leadsCount"
              value={player.leads ? player.leads : 0}
              placeholder="Leads"
            />
          </div>
        </div>
        {this.context.clientData &&
          this.context.clientData.clientUrl === 'bomnegocio' && (
            <>
              <div className="col-md-12">
                <h3>Dados do imóvel</h3>
                <br />
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    Quartos
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="leadsCount"
                    name="leadsCount"
                    disabled
                    value={player && player.patrimonyBedrooms}
                    placeholder=" Quartos"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    CEP
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="leadsCount"
                    name="leadsCount"
                    disabled
                    value={player && player.patrimonyCep}
                    placeholder=" CEP"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    Número
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="leadsCount"
                    name="leadsCount"
                    disabled
                    value={player && player.patrimonyNumber}
                    placeholder="Número"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    Área de lazer
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="leadsCount"
                    name="leadsCount"
                    disabled
                    value={player && player.patrimonyRecreation}
                    placeholder="Área de lazer"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    Metros²
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="leadsCount"
                    name="leadsCount"
                    disabled
                    value={player && player.patrimonySize}
                    placeholder="Metros²"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="profile-label" htmlFor="exampleInputEmail1">
                    Tempo de construção
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    id="leadsCount"
                    name="leadsCount"
                    disabled
                    value={player && player.patrimonyTimeConstruction}
                    placeholder="Tempo de construção"
                  />
                </div>
              </div>
            </>
          )}
        {player.influencer && player.influencer === true ? (
          <div className="col-md-6">
            <div className="form-group">
              <label className="profile-label" htmlFor="exampleInputEmail1">
                {this.translate('Código de Verificação')}
              </label>
              <input
                disabled
                readOnly
                type="text"
                className="form-control profile-placeholder"
                id="salesConversion"
                name="salesConversion"
                value={player.validationCode ? player.validationCode : '--'}
                placeholder={this.translate('Conversão')}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  renderProfilePicDetails() {
    const { player, pointsLeads } = this.state;

    player.availablePoints =
      Number(player.totalPoints ? player.totalPoints : 0) -
      Number(player.redeemedPoints ? player.redeemedPoints : 0);

    let colorPoints = '';
    if (player.totalPoints - pointsLeads > 0) {
      colorPoints = '#ff0000';
    } else if (player.totalPoints - pointsLeads < 0) {
      colorPoints = '#008000';
    }

    return (
      <div>
        <div className="oq-profile">
          <img
            src={player.photoURL ? player.photoURL : avatarImg}
            className="oq-profile-picture new-height"
            alt="player logo"
          />
        </div>
        <div className="oq-box">
          <div className="oq-points-box">
            <StyledRating value={this.state.rating} precision={0.1} readOnly />
            <br />
            <span className="oq-points-desc">Rating</span>
          </div>
        </div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points">
              {player.totalPoints ? player.totalPoints : 0}
            </span>
            <br />
            <span className="oq-points-desc">Pontos</span>
          </div>
        </div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points">
              {player.redeemedPoints ? player.redeemedPoints : 0}
            </span>
            <br />
            <span className="oq-points-desc">Pontos Resgatados</span>
          </div>
        </div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points">
              {player.availablePoints ? player.availablePoints : 0}
            </span>
            <br />
            <span className="oq-points-desc">Pontos Disponíveis</span>
          </div>
        </div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points" style={{ color: colorPoints }}>
              {pointsLeads || 0}
            </span>
            <br />
            <span className="oq-points-desc">Pontos dos Leads</span>
          </div>
        </div>
        <div className="oq-status-box row">
          <div className="col-md-4 col-md-offset-4 oq-status-box-switch">
            <ToggleButton
              disabled
              inactiveLabel={<i className="fa fa-times" aria-hidden="true" />}
              activeLabel={<i className="fa fa-check" aria-hidden="true" />}
              value={player.active}
              trackStyle={{ width: '100%' }}
            />
          </div>
        </div>
        {player && !player.active && player.disabledAt && (
          <div className="oq-box">
            <div className="oq-points-box">
              <span className="oq-points-desc">Desativado em</span>
              <br />
              <span className="oq-points">
                {moment(player.disabledAt).format('DD/MM/YYYY')}
              </span>
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '.5rem',
          }}
        >
          <span className="profile-label">Somente leitura</span>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <button
        type="button"
        className="btn btn-oq-back"
        onClick={() => this.props.history.goBack()}
      >
        <i className="fa fa-arrow-left" aria-hidden="true" />
        &nbsp; {this.translate('back')}
      </button>
    );
  }

  renderActionsTable() {
    const data = _.values(this.state.actions);

    const columns = [
      {
        Header: this.translate('name'),
        accessor: 'actionTitle',
      },
      {
        Header: this.translate('start-date'),
        id: 'startDateEpoch',
        accessor: (d) =>
          d.startDateEpoch ? moment(d.startDateEpoch).format('DD/MM/YYYY') : 0,
      },
      {
        Header: this.translate('end-date'),
        id: 'endDateEpoch',
        accessor: (d) =>
          d.endDateEpoch ? moment(d.endDateEpoch).format('DD/MM/YYYY') : 0,
      },
      {
        Header: this.translate('totalPontuation'),
        id: 'points',
        accessor: (d) =>
          d.points ? this.numberWithCommas(Number(d.points).toFixed(1)) : 0,
      },
      {
        Header: this.translate('Posição'),
        id: 'position',
        accessor: (d) => (d.position ? `${d.position}º` : 'Carregando...'),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (props) => {
          return (
            <span className="text-center clickable">
              <Link
                to={`${routes.EDIT_ACTION_VIRTUAL}/${props.value}`}
                className="btn btn-oq btn-sm btn-block"
              >
                {this.translate('DETALHES')}
              </Link>
            </span>
          );
        },
      },
    ];
    return (
      <ReactTable
        data={data}
        loading={this.state.loadingActions}
        defaultPageSize={5}
        columns={columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhuma ação para ser exibida')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText={this.translate('actions')}
      />
    );
  }

  renderTeamMembersTable() {
    const data = _.values(this.state.teamMembers);

    const columns = [
      {
        Header: this.translate('Avatar'),
        Cell: (row) => {
          return (
            <div>
              <img
                style={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '16px',
                }}
                src={row.original.photoURL}
              />
            </div>
          );
        },
        minWidth: 15,
      },
      {
        Header: this.translate('name'),
        accessor: 'name',
      },
    ];
    return (
      <ReactTable
        data={data}
        loading={this.state.loadingTeamMembers}
        defaultPageSize={5}
        columns={columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhum membro da equipe para ser exibido')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText={this.translate('Membros')}
      />
    );
  }

  renderPlayersIndicatedTable() {
    const columns = [
      {
        Header: this.translate('name'),
        accessor: 'name',
      },
      {
        Header: this.translate('email'),
        accessor: 'email',
      },
      {
        Header: 'CPF',
        accessor: 'cpf',
      },
      {
        Header: this.translate('date'),
        id: 'createdAt',
        accessor: (d) =>
          d.createdAt ? moment(d.createdAt).format('DD/MM/YYYY') : 0,
      },
    ];
    return (
      <ReactTable
        data={this.state.playersIndicated}
        loading={this.state.loadingPlayers}
        defaultPageSize={5}
        columns={columns}
        previousText={this.translate('prev')}
        nextText={this.translate('next')}
        loadingText={this.translate('loading')}
        noDataText={this.translate('Nenhum usuário para ser exibida')}
        pageText={this.translate('page')}
        ofText={this.translate('of')}
        rowsText={this.translate('players')}
      />
    );
  }

  numberWithCommas(x) {
    const decimalComma = x.replace('.', ',');
    const parts = decimalComma.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }

  renderTargetPublicList() {
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          &nbsp; {this.translate('Lista de publicações do usuário')}
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>{this.renderActionsTable()}</div>
      </div>
    );
  }

  renderListTeamMembers() {
    const { teamLeader } = this.state;
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          &nbsp;{' '}
          {this.translate(
            `${
              teamLeader
                ? `Lista da equipe ${
                    (teamLeader && teamLeader.name) || 'carregando'
                  }`
                : 'Lista da equipe'
            }`
          )}
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>{this.renderTeamMembersTable()}</div>
      </div>
    );
  }

  renderPlayersIndicated() {
    return (
      <div className="oq-card">
        <span className="oq-card-title">
          <i className="fa fa-rocket" />
          &nbsp; {this.translate('Lista de players indicados')}
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>
          {this.renderPlayersIndicatedTable()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="mainSection">
              <div className="row">{this.renderBackButton()}</div>
              <h1 className="row oq-title">
                {this.translate('Detalhes do usuário')}
              </h1>
              <div className="row">
                <div className="col-md-3">{this.renderProfilePicDetails()}</div>
                <div className="col-md-9">{this.renderProfileFields()}</div>
              </div>
              {this.context.clientData &&
                this.context.clientData.isTeamCompetition &&
                this.context.clientData.isTeamCompetition === 'true' && (
                  <div className="col-md-12">
                    {this.renderListTeamMembers()}
                  </div>
                )}
              <div className="col-md-12">{this.renderTargetPublicList()}</div>
              {this.state.playersIndicated && (
                <div className="col-md-12">{this.renderPlayersIndicated()}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailsPlayer;
