import '../EditActionVirtual/styles.css';
import React, { useState } from 'react';
import cancel from '../../../../../Assets/Images/cancel-logo.png';
import analyticsLogo from '../../../../../Assets/Images/analytics-logo.png';
import pixelLogo from '../../../../../Assets/Images/pixel-logo.png';
import rdStationLogo from '../../../../../Assets/Images/rdStation-logo.png';

export function Integrations({
  handleIntegration,
  onChange,
  selected,
  value,
  saved,
}) {
  const [selectedIntegration, setSelectedIntegration] = useState(selected);
  const [valueCodeIntegration, setValueCodeIntegration] = useState(value);
  const [isSaved, setIsSaved] = useState(saved);

  return (
    <>
      {isSaved && (
        <>
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">Integração feita!</h4>
            <p>
              {`Esta campanha/publicação está integrada com o ${
                (selected === 0 && 'Pixel do Facebook') ||
                (selected === 1 && 'Google Analytics') ||
                (selected === 2 && 'Rd Station Marketing')
              }.`}
            </p>
          </div>
          <hr />
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Remover integração</h4>
            <p>
              {`Se remover a integração com o ${
                (selected === 0 && 'Pixel do Facebook') ||
                (selected === 1 && 'Google Analytics') ||
                (selected === 2 && 'Rd Station Marketing')
              } você não receberá mais dados desta campanha/publicação!`}
            </p>
            <hr />
            <p className="mb-0">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  handleIntegration(3);
                  setSelectedIntegration(3);
                  setValueCodeIntegration('');
                  setIsSaved(false);
                }}
              >
                Remover integração
              </button>
            </p>
          </div>
        </>
      )}

      {!isSaved && (
        <>
          <label className="profile-label">
            Passo 1: Escolha uma das integrações
          </label>
          <br />
          <div className="row">
            <div className="col-md-3">
              <div
                onClick={() => {
                  setSelectedIntegration(0);
                  handleIntegration(0);
                  setValueCodeIntegration('');
                }}
                className={`card p-3 trigger border bg-white rounded ${
                  selectedIntegration === 0 && 'selected'
                }`}
              >
                <div className="card-body center-all">
                  <img src={pixelLogo} alt="" width={230} />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                onClick={() => {
                  setSelectedIntegration(1);
                  handleIntegration(1);
                  setValueCodeIntegration('');
                }}
                className={`card p-3 trigger border bg-white rounded ${
                  selectedIntegration === 1 && 'selected'
                }`}
              >
                <div className="card-body center-all">
                  <img src={analyticsLogo} alt="" width={150} />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                onClick={() => {
                  setSelectedIntegration(2);
                  handleIntegration(2);
                  setValueCodeIntegration('');
                }}
                className={`card p-3 trigger border bg-white rounded ${
                  selectedIntegration === 2 && 'selected'
                }`}
              >
                <div className="card-body center-all">
                  <img src={rdStationLogo} alt="" width={150} />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                onClick={() => {
                  setSelectedIntegration(3);
                  handleIntegration(3);
                  setValueCodeIntegration('');
                }}
                className={`card p-3 trigger border bg-white rounded ${
                  selectedIntegration === 3 && 'selected'
                }`}
              >
                <div className="card-body center-all">
                  <img src={cancel} alt="" width={50} />
                </div>
              </div>
            </div>
          </div>
          {selectedIntegration !== 3 && (
            <>
              <br />
              <label className="profile-label">
                Passo 2: Cole aqui o código que ficará na tag{' '}
                <strong>head</strong>
              </label>
              <br />
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows="10"
                  value={valueCodeIntegration}
                  onChange={(e) => {
                    onChange(e);
                    setValueCodeIntegration(e.target.value);
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
